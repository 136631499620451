<template>
  <div class="home">


    <div class="home_part">
      <h3>产品手册</h3>
      <div class="models">
        <div class="model" v-for="item in products" :key="item.id" @click="toDetail(item, 1, '产品手册')">
          <div class="_title">{{item.catalog_name}}</div>
          <div class="_more">查看更多<i></i></div>
        </div>
      </div>
    </div>
    <div class="home_part">
      <h3>专题手册</h3>
      <div class="models">
        <div class="model" v-for="item in specials" :key="item.id" @click="toDetail(item, 2, item.special_name)">
          <div class="_title">{{item.special_name}}</div>
          <div class="_more">查看更多<i></i></div>
        </div>
      </div>
    </div>


    <div class="home_part">
      <h3>新品推荐</h3>
      <div class="models">
        <div class="model" @click="toDetail({}, 3, '新品推荐')">
          <div class="_title">产品上新</div>
          <div class="_more">查看更多<i></i></div>
        </div>
      </div>
    </div>

    <div class="home_part">
      <h3>产品查询</h3>
      <div class="models">
        <div class="model" @click="toDetail({}, 4, '型号查询')">
          <div class="_title">型号查询</div>
          <div class="_more">查看更多<i></i></div>
        </div>
        <div class="model" @click="toDetail({}, 5, '指标查询')">
          <div class="_title">指标查询</div>
          <div class="_more">查看更多<i></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from "@conf";
export default {
  name: "Home",
  components: {

  },
  data() {
    return {
      products: [], // 产品手册
      specials: [], // 专题手册
      title: '',
      message: '',
      newProducts: [
        {
          id: 0,
          desc: '产品上新'
        }
      ], // 新品推荐
      searchProduct: [
        {
          type: 4,
          id: 0,
          desc: '型号查询'
        },
        {
          type: 5,
          id: 0,
          desc: '指标查询'
        }
      ], // 产品查询
    }
  },
  methods: {
    getData() {
      Api.getHomeData().then(res => {
        const { data = [] } = res
        const { products, specials } = data
        this.products.push(...products)
        this.specials.push(...specials)
      })
    },
    toDetail(item = {}, type, title) {
      const { id = 0 } = item
      this.$router.push(`/detail?cid=${id}&type=${type}&title=${title}`)
    },
  },
  mounted() {
    this.getData()
  }
}
</script>

<style lang="less" src="./Index.less"></style>
